import React from 'react';
import { Box } from '@mui/material';
import serviceCards from '#/components/pages/SuccessorDashboard/serviceCards';
import DashboardCard from '#/components/shared/dashboard-card';
import Extras from '#/components/pages/SuccessorDashboard/extras/extras';
import useLocales from '#/hooks/useLocales';
import useAccountSwitch from '#/hooks/useAccountSwitch';

export default function SServicesList() {
  const { translate } = useLocales();
  const { currentAccount } = useAccountSwitch();

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: 2,
      }}
    >
      {serviceCards.map((card, i) => (
        <DashboardCard
          title={card.title}
          icon={card.icon}
          description={card.description}
          key={`${card.title + i}`}
          tooltipTitle={String(
            translate('global.dashCardInfos.guest.disabledButton')
          )}
          showTooltip={
            // @ts-ignore
            !currentAccount?.docs[card.serviceKey as string] && card.docType
          }
          actions={
            <Extras
              // @ts-ignore
              serviceDoc={currentAccount?.docs[card.serviceKey as string]}
              {...card}
              ownerID={currentAccount?.id}
            />
          }
        />
      ))}
    </Box>
  );
}
