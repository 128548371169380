import { Stack } from '@mui/material';
import {
  DownloadSignedDocument,
  ViewService,
} from '#/components/pages/SuccessorDashboard/extras/card-actions';

type ExtrasProps = {
  serviceDoc: string;
  docType?: boolean;
  viewURL?: string;
  ownerID?: string;
};

export default function Extras({
  serviceDoc,
  docType,
  viewURL,
  ownerID,
}: ExtrasProps) {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
    >
      {docType && <DownloadSignedDocument downloadLink={serviceDoc} />}
      {viewURL && <ViewService toServiceURL={viewURL} ownerID={ownerID} />}
    </Stack>
  );
}
